import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

interface ILayoutProps {
  children: React.ReactNode
  location?: any
}

export const Layout: FC<ILayoutProps> = ({ children, location }) => {
  let content

  if (location && location.pathname === '/') {
    content = <div>{children}</div>
  } else {
    content = (
      <div id='wrapper' className='page'>
        <div>{children}</div>
      </div>
    )
  }

  return (
    <>
      <Helmet
        lang='fr'
        title='AFFL'
        meta={[
          {
            charset: 'utf-8',
            content: 'franz, liszt, association',
            description: 'Association française Franz Liszt',
            name: 'keywords',
          },
        ]}
      >
        <html lang='fr' />
      </Helmet>
      {content}
    </>
  )
}
